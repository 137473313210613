"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECurrency = void 0;
var ECurrency;
(function (ECurrency) {
    ECurrency["AED"] = "AED";
    ECurrency["AFN"] = "AFN";
    ECurrency["ALL"] = "ALL";
    ECurrency["AMD"] = "AMD";
    ECurrency["ANG"] = "ANG";
    ECurrency["AOA"] = "AOA";
    ECurrency["ARS"] = "ARS";
    ECurrency["AUD"] = "AUD";
    ECurrency["AWG"] = "AWG";
    ECurrency["AZN"] = "AZN";
    ECurrency["BAM"] = "BAM";
    ECurrency["BBD"] = "BBD";
    ECurrency["BDT"] = "BDT";
    ECurrency["BGN"] = "BGN";
    ECurrency["BHD"] = "BHD";
    ECurrency["BIF"] = "BIF";
    ECurrency["BMD"] = "BMD";
    ECurrency["BND"] = "BND";
    ECurrency["BOB"] = "BOB";
    ECurrency["BRL"] = "BRL";
    ECurrency["BSD"] = "BSD";
    ECurrency["BTN"] = "BTN";
    ECurrency["BWP"] = "BWP";
    ECurrency["BYN"] = "BYN";
    ECurrency["BZD"] = "BZD";
    ECurrency["CAD"] = "CAD";
    ECurrency["CDF"] = "CDF";
    ECurrency["CHF"] = "CHF";
    ECurrency["CLF"] = "CLF";
    ECurrency["CLP"] = "CLP";
    ECurrency["CNH"] = "CNH";
    ECurrency["CNY"] = "CNY";
    ECurrency["COP"] = "COP";
    ECurrency["CRC"] = "CRC";
    ECurrency["CUC"] = "CUC";
    ECurrency["CUP"] = "CUP";
    ECurrency["CVE"] = "CVE";
    ECurrency["CZK"] = "CZK";
    ECurrency["DJF"] = "DJF";
    ECurrency["DKK"] = "DKK";
    ECurrency["DOP"] = "DOP";
    ECurrency["DZD"] = "DZD";
    ECurrency["EGP"] = "EGP";
    ECurrency["ERN"] = "ERN";
    ECurrency["ETB"] = "ETB";
    ECurrency["EUR"] = "EUR";
    ECurrency["FJD"] = "FJD";
    ECurrency["FKP"] = "FKP";
    ECurrency["GBP"] = "GBP";
    ECurrency["GEL"] = "GEL";
    ECurrency["GGP"] = "GGP";
    ECurrency["GHS"] = "GHS";
    ECurrency["GIP"] = "GIP";
    ECurrency["GMD"] = "GMD";
    ECurrency["GNF"] = "GNF";
    ECurrency["GTQ"] = "GTQ";
    ECurrency["GYD"] = "GYD";
    ECurrency["HKD"] = "HKD";
    ECurrency["HNL"] = "HNL";
    ECurrency["HRK"] = "HRK";
    ECurrency["HTG"] = "HTG";
    ECurrency["HUF"] = "HUF";
    ECurrency["IDR"] = "IDR";
    ECurrency["ILS"] = "ILS";
    ECurrency["IMP"] = "IMP";
    ECurrency["INR"] = "INR";
    ECurrency["IQD"] = "IQD";
    ECurrency["IRR"] = "IRR";
    ECurrency["ISK"] = "ISK";
    ECurrency["JEP"] = "JEP";
    ECurrency["JMD"] = "JMD";
    ECurrency["JOD"] = "JOD";
    ECurrency["JPY"] = "JPY";
    ECurrency["KES"] = "KES";
    ECurrency["KGS"] = "KGS";
    ECurrency["KHR"] = "KHR";
    ECurrency["KMF"] = "KMF";
    ECurrency["KPW"] = "KPW";
    ECurrency["KRW"] = "KRW";
    ECurrency["KWD"] = "KWD";
    ECurrency["KYD"] = "KYD";
    ECurrency["KZT"] = "KZT";
    ECurrency["LAK"] = "LAK";
    ECurrency["LBP"] = "LBP";
    ECurrency["LKR"] = "LKR";
    ECurrency["LRD"] = "LRD";
    ECurrency["LSL"] = "LSL";
    ECurrency["LYD"] = "LYD";
    ECurrency["MAD"] = "MAD";
    ECurrency["MDL"] = "MDL";
    ECurrency["MGA"] = "MGA";
    ECurrency["MKD"] = "MKD";
    ECurrency["MMK"] = "MMK";
    ECurrency["MNT"] = "MNT";
    ECurrency["MOP"] = "MOP";
    ECurrency["MRU"] = "MRU";
    ECurrency["MUR"] = "MUR";
    ECurrency["MVR"] = "MVR";
    ECurrency["MWK"] = "MWK";
    ECurrency["MXN"] = "MXN";
    ECurrency["MYR"] = "MYR";
    ECurrency["MZN"] = "MZN";
    ECurrency["NAD"] = "NAD";
    ECurrency["NGN"] = "NGN";
    ECurrency["NIO"] = "NIO";
    ECurrency["NOK"] = "NOK";
    ECurrency["NPR"] = "NPR";
    ECurrency["NZD"] = "NZD";
    ECurrency["OMR"] = "OMR";
    ECurrency["PAB"] = "PAB";
    ECurrency["PEN"] = "PEN";
    ECurrency["PGK"] = "PGK";
    ECurrency["PHP"] = "PHP";
    ECurrency["PKR"] = "PKR";
    ECurrency["PLN"] = "PLN";
    ECurrency["PYG"] = "PYG";
    ECurrency["QAR"] = "QAR";
    ECurrency["RON"] = "RON";
    ECurrency["RSD"] = "RSD";
    ECurrency["RUB"] = "RUB";
    ECurrency["RWF"] = "RWF";
    ECurrency["SAR"] = "SAR";
    ECurrency["SBD"] = "SBD";
    ECurrency["SCR"] = "SCR";
    ECurrency["SDG"] = "SDG";
    ECurrency["SEK"] = "SEK";
    ECurrency["SGD"] = "SGD";
    ECurrency["SHP"] = "SHP";
    ECurrency["SLL"] = "SLL";
    ECurrency["SOS"] = "SOS";
    ECurrency["SRD"] = "SRD";
    ECurrency["SSP"] = "SSP";
    ECurrency["STD"] = "STD";
    ECurrency["STN"] = "STN";
    ECurrency["SVC"] = "SVC";
    ECurrency["SYP"] = "SYP";
    ECurrency["SZL"] = "SZL";
    ECurrency["THB"] = "THB";
    ECurrency["TJS"] = "TJS";
    ECurrency["TMT"] = "TMT";
    ECurrency["TND"] = "TND";
    ECurrency["TOP"] = "TOP";
    ECurrency["TRY"] = "TRY";
    ECurrency["TTD"] = "TTD";
    ECurrency["TWD"] = "TWD";
    ECurrency["TZS"] = "TZS";
    ECurrency["UAH"] = "UAH";
    ECurrency["UGX"] = "UGX";
    ECurrency["USD"] = "USD";
    ECurrency["UYU"] = "UYU";
    ECurrency["UZS"] = "UZS";
    ECurrency["VEF"] = "VEF";
    ECurrency["VES"] = "VES";
    ECurrency["VND"] = "VND";
    ECurrency["VUV"] = "VUV";
    ECurrency["WST"] = "WST";
    ECurrency["XAF"] = "XAF";
    ECurrency["XAG"] = "XAG";
    ECurrency["XAU"] = "XAU";
    ECurrency["XCD"] = "XCD";
    ECurrency["XDR"] = "XDR";
    ECurrency["XOF"] = "XOF";
    ECurrency["XPD"] = "XPD";
    ECurrency["XPF"] = "XPF";
    ECurrency["XPT"] = "XPT";
    ECurrency["YER"] = "YER";
    ECurrency["ZAR"] = "ZAR";
    ECurrency["ZMW"] = "ZMW";
    ECurrency["ZWL"] = "ZWL";
})(ECurrency || (exports.ECurrency = ECurrency = {}));
