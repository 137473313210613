"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EUserPaymentStatementType = void 0;
var EUserPaymentStatementType;
(function (EUserPaymentStatementType) {
    EUserPaymentStatementType["TIPS"] = "tips";
    EUserPaymentStatementType["MESSAGES"] = "messages";
    EUserPaymentStatementType["SUBSCRIPTIONS"] = "subscriptions";
    EUserPaymentStatementType["POSTS"] = "posts";
    EUserPaymentStatementType["PAYOUT"] = "payout";
})(EUserPaymentStatementType || (exports.EUserPaymentStatementType = EUserPaymentStatementType = {}));
